/* eslint-disable react/no-array-index-key */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import get from 'lodash/get';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import ContentBlock from '../components/content-block';
import ContactForm from '../components/Forms/ContactForm';
import PageBanner from '../components/page-banner';
import 'react-image-lightbox/style.css';

class ServicesPage extends React.Component {
  render() {
    const { data } = this.props;
    const { content } = data.allContentfulPage.edges[0].node;
    const ServiceImages = get(this, 'props.data.allContentfulService.edges');

    return (
      <Layout>
        <Header headerType="" />
        <SEO title="Services" />
        <PageBanner />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <AniLink fade className="home" to="/">
                      Home
                      <span>/</span>
                    </AniLink>
                  </li>
                  <li>
                    <AniLink fade className="current" to="/services">
                      Services
                    </AniLink>
                  </li>
                </ul>
              </div>
              <h1>Services</h1>
            </div>
            {content.map((block, index) => (
              <ContentBlock
                content={block}
                images={ServiceImages}
                key={index}
                position={index}
              />
            ))}
          </div>
        </div>
        <ContactForm
          heading="Start with a free quote!"
          subheading="One our team will be in contact to help you with your request. Obligation free."
          theme="grey"
          formType="project"
        />
      </Layout>
    );
  }
}

export default ServicesPage;

ServicesPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export const serviceQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "services" } }) {
      edges {
        node {
          id
          content {
            ... on ContentfulContentBlock {
              title
              childContentfulContentBlockTextRichTextNode {
                childContentfulRichText {
                  html
                }
              }
              image {
                id
                title
                fluid(maxWidth: 1600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allContentfulService(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          title
          text {
            text
          }
          serviceImages {
            blurb
            image {
              id
              title
              description
              fluid(maxWidth: 1600) {
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`;
