/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import get from 'lodash/get';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

class ContentBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  /**
   * Render image into the content block
   * if this has been set in Contentful
   * otherwise return null
   *
   * @param {Node} image
   */
  renderImage(image) {
    return image ? (
      <div className="page-banner-image content-block-image">
        <Img fluid={image.fluid} alt={image.title} className="fill-x" />
      </div>
    ) : null;
  }

  DisableBodyScroll = () => {
    if (typeof document !== 'undefined') {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.isOpen) {
        document.querySelector('html').classList.add('react-modal-open');
      } else {
        document.querySelector('html').classList.remove('react-modal-open');
      }
    }
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { content, images, position } = this.props;
    const text = get(
      content,
      'childContentfulContentBlockTextRichTextNode.childContentfulRichText.html'
    );

    const serviceImages = images[position].node.serviceImages
      ? images[position].node.serviceImages
      : [];

    const length = serviceImages.length;

    const captionsArr = serviceImages.map(function(img) {
      return img.blurb;
    });

    const imagesArr = serviceImages
      .filter(img => img.image !== null)
      .map(img => img.image.fluid.srcWebp);

    return (
      <Fade delay={500}>
        <>
          <div className="container contact-us my3">
            <div className="row">
              <a name={`/services#${content.title.toLowerCase()}`} />
              <div className="col-12">
                {imagesArr.length ? (
                  <button
                    type="button"
                    className="open-lightbox"
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    <h4>{content.title}</h4>
                  </button>
                ) : (
                  <h4>{content.title}</h4>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
                {this.renderImage(content.image)}
                {this.DisableBodyScroll()}
                {isOpen && (
                  <Lightbox
                    mainSrc={imagesArr[photoIndex]}
                    imageCaption={captionsArr[photoIndex]}
                    nextSrc={imagesArr[(photoIndex + 1) % images.length]}
                    prevSrc={imagesArr[(photoIndex + length - 1) % length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + length - 1) % length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % length,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>
      </Fade>
    );
  }
}

export default ContentBlock;

ContentBlock.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  images: PropTypes.array.isRequired,
  position: PropTypes.number.isRequired,
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    image: PropTypes.object,
  }),
};
